<footer class="bg-white">
  <div class="container pt-5 pb-3">

    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
        <p class="title">Bemutatkozás:</p>
        <p>Fedezze fel a Riz Levente Sport- és Rendezvényközpontot a 17. kerületben: modern, multifunkcionális helyszín sportmérkőzésekhez, koncertekhez, előadásokhoz. Kínálatunk: impozáns előtér, büfé,
           2000 négyzetméteres küzdőtér, 1200 fős lelátó, fitness- és wellness részleg, mászófal, táncstúdió, VIP terem. Ideális minden rendezvényhez.</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
        <p class="title">Elérhetőség:</p>
        <p>Fedezze fel a multifunkciós sportcsarnokunk sokoldalú lehetőségeit köztük a felszerelt konditermet és ízletes büfét!</p>
        <p>A következő felületeken tudsz elérni minket:</p>
        <p><b>Cím: </b>1173 Budapest, Pesti út 88.</p>
        <p><b>Email: </b><a href="mailto:rsr@rsr.hu">rsr@rsr.hu <i class="fa-regular fa-arrow-up-right-from-square"></i></a></p>
        <p class="mb-0"><b>Telefon: </b><a href="tel:+36204058590">+36 20 405 8590 <i class="fa-regular fa-arrow-up-right-from-square"></i></a></p>
        <p style="font-size: .7rem;">hétfőtől - csütörtökig: 09-16, pénteken: 09-13 óra között</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
        <p class="title">Nyitvatartás:</p>
        <p>Hétfő: 08:00 - 21:00</p>
        <p>Kedd: 08:00 - 21:00</p>
        <p>Szerda: 08:00 - 21:00</p>
        <p>Csütörtök: 08:00 - 21:00</p>
        <p>Péntek: 08:00 - 21:00</p>
        <p>Szombat: 08:00 - 21:00</p>
        <p>Vasárnap: 08:00 - 21:00</p>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <p class="title">Hasznos lehet:</p>
        <p><a routerLink="/nyaritabor" routerLinkActive="link-active">Nyári tábor</a>, <a routerLink="/eredmenyek" routerLinkActive="link-active">Futóverseny</a></p>
        <p><a routerLink="/impresszum" routerLinkActive="link-active">Impresszum</a>, <a routerLink="/hazirend" routerLinkActive="link-active">Házirend</a></p>
        <p><a routerLink="/adatvedelmi-nyilatkozat" routerLinkActive="link-active">Adatvédelmi nyilatkozat</a></p>
        <p><a routerLink="/adatvedelmi-nyilatkozat-hirlevel" routerLinkActive="link-active">Adatvédelmi nyilatkozat - hírlevél</a></p>
        <p><a routerLink="/cookies-nyilatkozat" routerLinkActive="link-active">Cookies nyilatkozat</a></p>
        <p><a routerLink="/panaszkezelesi-szabalyzat" routerLinkActive="link-active">Panaszkezelési szabályzat</a></p>
        <p><a href="https://www.rakosmente.hu" target="_blank">Rákosmente Önkormányzata <i class="fa-regular fa-arrow-up-right-from-square"></i></a></p>
      </div>
    </div>

  </div>
  <hr>
  <div class="container pb-3">

    <div class="row">
      <div class="col-12 col-lg-4 my-2 my-lg-0 d-flex align-items-center text-center text-lg-start">
        <p class="mb-0 w-100"><i class="fa-regular fa-copyright"></i> 2022 - {{ year }} RSR. Minden jog fenntartva.</p>
      </div>
      <div class="col-12 col-lg-4 my-2 my-lg-0 d-flex align-items-center text-center">
        <p class="mb-0 w-100 button-group">
          <a href="https://www.facebook.com/RakosmenteRSR" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/riz_levente_sport" target="_blank"><i class="fa-brands fa-instagram"></i></a>
          <a href="https://www.youtube.com/channel/UCyhchqvyRTiNERTeM-Ok3HA" target="_blank"><i class="fa-brands fa-youtube"></i></a>
        </p>
      </div>
      <div class="col-12 col-lg-4 my-2 my-lg-0 text-center text-lg-end">
        <p class="mb-0">
          <a href="https://www.elitit.hu" target="_blank">
            <img src="https://cdn.hostpark.hu/elit-it/logo-dark.svg" height="50px" alt="Tervezte és fejlesztette: Elit IT">
          </a>
        </p>
      </div>
    </div>

  </div>
</footer>
