<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light p-0">
  <div class="container">

    <a class="navbar-brand" routerLink="/" (click)="closeNavbar()">
      <img src="assets/rsr-logo.png" height="50" alt="Riz Levente Sport- és Rendezvényközpont logó" loading="lazy">
    </a>

    <button class="navbar-toggler" matRipple (click)="navbar.toggle()">
      <i class="fas fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" mdbCollapse #navbar="mdbCollapse">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/" routerLinkActive="nav-link-active" [routerLinkActiveOptions]="{exact: true}" (click)="closeNavbar()">Főoldal</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/hirek" routerLinkActive="nav-link-active" (click)="closeNavbar()">Hírek</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/esemenyek" routerLinkActive="nav-link-active" (click)="closeNavbar()">Események</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/terembeosztas" routerLinkActive="nav-link-active" (click)="closeNavbar()">Terembeosztás</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/csarnok" routerLinkActive="nav-link-active" (click)="closeNavbar()">Csarnok</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/szolgaltatasok" routerLinkActive="nav-link-active" (click)="closeNavbar()">Szolgáltatások</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" matRipple routerLink="/kapcsolat" routerLinkActive="nav-link-active" (click)="closeNavbar()">Kapcsolat</a>
        </li>

      </ul>
    </div>

  </div>
</nav>

<div style="width: 100%; height: 59.58px; z-index: -1"></div>
